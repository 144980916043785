/**
 * @generated SignedSource<<9cc679aeca355f7de4286fcfcb8fce53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductRegistrationSectionOccurrenceFragment$data = {
  readonly atCapacity: boolean;
  readonly id: string;
  readonly " $fragmentType": "ProductRegistrationSectionOccurrenceFragment";
};
export type ProductRegistrationSectionOccurrenceFragment$key = {
  readonly " $data"?: ProductRegistrationSectionOccurrenceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductRegistrationSectionOccurrenceFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductRegistrationSectionOccurrenceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "atCapacity",
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};

(node as any).hash = "a17409461f20d53f348c22e3fffd1fa8";

export default node;
