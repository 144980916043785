/**
 * @generated SignedSource<<a646cba1fc616961d8cd7877ffb0ef8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductRegistrationPaymentStep_Checkout$data = {
  readonly id: string;
  readonly product: {
    readonly pricing: {
      readonly " $fragmentSpreads": FragmentRefs<"PaymentFormDiscountSection_Pricing">;
    } | null;
    readonly type: ProductType;
  };
  readonly quote: {
    readonly basePrice: number;
    readonly discount: {
      readonly " $fragmentSpreads": FragmentRefs<"PaymentFormDiscountSection_Discount">;
    } | null;
    readonly finalPrice: number;
  };
  readonly " $fragmentType": "ProductRegistrationPaymentStep_Checkout";
};
export type ProductRegistrationPaymentStep_Checkout$key = {
  readonly " $data"?: ProductRegistrationPaymentStep_Checkout$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductRegistrationPaymentStep_Checkout">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductRegistrationPaymentStep_Checkout",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pricing",
          "kind": "LinkedField",
          "name": "pricing",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PaymentFormDiscountSection_Pricing"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckoutQuote",
      "kind": "LinkedField",
      "name": "quote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Discount",
          "kind": "LinkedField",
          "name": "discount",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PaymentFormDiscountSection_Discount"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Checkout",
  "abstractKey": null
};

(node as any).hash = "3eefd838904e15679357ff77f9cc1866";

export default node;
