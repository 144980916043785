/**
 * @generated SignedSource<<d80d6c64ecb00ce234d4a0c7747a62ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProductVisibilityBannerQuery$variables = {
  productId: string;
};
export type ProductVisibilityBannerQuery$data = {
  readonly node: {
    readonly hasDraftChildProducts?: boolean;
  } | null;
};
export type ProductVisibilityBannerQuery = {
  response: ProductVisibilityBannerQuery$data;
  variables: ProductVisibilityBannerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasDraftChildProducts",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductVisibilityBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductVisibilityBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55f52b2051152afb72005131c98d1695",
    "id": null,
    "metadata": {},
    "name": "ProductVisibilityBannerQuery",
    "operationKind": "query",
    "text": "query ProductVisibilityBannerQuery(\n  $productId: ID!\n) {\n  node(id: $productId) {\n    __typename\n    ... on Product {\n      hasDraftChildProducts\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3864539c284cf58526547cc91fcea59e";

export default node;
