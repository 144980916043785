/**
 * @generated SignedSource<<7d1198cbbabf4827285396f74444e959>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type productUtils_useIsProductLiveFragment$data = {
  readonly nextOrLastOccurrence: {
    readonly datetimeRange: ReadonlyArray<string>;
  } | null;
  readonly type: ProductType;
  readonly " $fragmentType": "productUtils_useIsProductLiveFragment";
};
export type productUtils_useIsProductLiveFragment$key = {
  readonly " $data"?: productUtils_useIsProductLiveFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productUtils_useIsProductLiveFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "productUtils_useIsProductLiveFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Occurrence",
      "kind": "LinkedField",
      "name": "nextOrLastOccurrence",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "datetimeRange",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "c96149aaf7a47e479ca68ff137b3b1c1";

export default node;
