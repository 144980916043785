import {
  NAV_SECTIONS_DROPPABLE_TYPE,
  SidebarDragDropContext,
} from "@/apps/sidebar-item/AppsSidebarDragDropProvider"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { ProductsSidebarDragDropProvider_ReorderNavSectionMutation } from "@/product/sidebar/__generated__/ProductsSidebarDragDropProvider_ReorderNavSectionMutation.graphql"
import { ProductsSidebarDragDropProvider_ReorderProductMutation } from "@/product/sidebar/__generated__/ProductsSidebarDragDropProvider_ReorderProductMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { ReactNode, useContext, useState } from "react"
import {
  BeforeCapture,
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
  Droppable,
} from "react-beautiful-dnd"
import ConnectionHandler from "relay-connection-handler-plus"
import { RecordSourceSelectorProxy, graphql } from "relay-runtime"

const TOP_LEVEL_PRODUCTS_DROPPABLE_ID = "top-level-products"
const PRODUCTS_DROPPABLE_TYPE = "products"

type ProductsSidebarDragDropProviderProps = {
  children: ReactNode
}

export default function ProductsSidebarDragDropProvider({
  children,
}: ProductsSidebarDragDropProviderProps) {
  const activeOrganization = useActiveOrganization()!
  const [draggingId, setDraggingId] = useState("")

  const reorderProduct =
    Relay.useAsyncMutation<ProductsSidebarDragDropProvider_ReorderProductMutation>(
      graphql`
        mutation ProductsSidebarDragDropProvider_ReorderProductMutation(
          $input: ReorderProductInput!
        ) {
          response: reorderProduct(input: $input) {
            node {
              id
              navSectionId
              navOrdering
            }
            errors {
              field
              message
            }
          }
        }
      `
    )

  const reorderNavSection =
    Relay.useAsyncMutation<ProductsSidebarDragDropProvider_ReorderNavSectionMutation>(
      graphql`
        mutation ProductsSidebarDragDropProvider_ReorderNavSectionMutation(
          $input: ReorderNavSectionInput!
        ) {
          response: reorderNavSection(input: $input) {
            node {
              id
              ordering
            }
            errors {
              field
              message
            }
          }
        }
      `
    )

  return (
    <DragDropContext onDragEnd={handleDragEnd} onBeforeCapture={handleBeforeCapture}>
      <SidebarDragDropContext.Provider value={{ draggingId }}>
        {children}
      </SidebarDragDropContext.Provider>
    </DragDropContext>
  )

  function handleBeforeCapture(before: BeforeCapture) {
    // Set the dragging ID before dragging begins so we can collapse it the dragged
    // item before dimensions are captured
    setDraggingId(before.draggableId)
  }

  function handleDragEnd(result: DropResult) {
    setDraggingId("")
    const { source, destination, type } = result
    if (!destination) return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return
    switch (type) {
      case PRODUCTS_DROPPABLE_TYPE:
        handleProductDragEnd(result)
        break
      case NAV_SECTIONS_DROPPABLE_TYPE:
        handleNavSectionDragEnd(result)
        break
    }
  }

  async function handleProductDragEnd(result: DropResult) {
    const { source, draggableId: productId } = result
    const destination = result.destination!
    const fromSection =
      source.droppableId === TOP_LEVEL_PRODUCTS_DROPPABLE_ID ? null : source.droppableId
    const toSection =
      destination.droppableId === TOP_LEVEL_PRODUCTS_DROPPABLE_ID
        ? null
        : destination.droppableId

    await reorderProduct(
      {
        input: {
          productId,
          ordering: destination!.index,
          navSectionId: toSection,
        },
      },
      {
        optimisticUpdater: storeUpdater,
        updater: (store, { response }) => {
          if (!response.errors) storeUpdater(store)
        },
      }
    )

    function storeUpdater(store: RecordSourceSelectorProxy) {
      const sourceParentRecord = store.get(fromSection || activeOrganization.id)
      if (!sourceParentRecord) return
      const sourceConnection = ConnectionHandler.getConnections(
        sourceParentRecord,
        fromSection
          ? "ProductsSidebarList_NavSectionPaginationFragment__products"
          : "ProductsSidebarList_OrganizationPaginationFragment__topLevelProducts"
      )[0]
      if (!sourceConnection) return

      // If product was dragged within its current section
      if (fromSection === toSection) {
        Relay.reorderEdgeInConnection(sourceConnection, source.index, destination.index)
        return
      }

      // If product was dragged into a different section, we need to move it between connections
      const destinationParentRecord = store.get(toSection || activeOrganization.id)
      if (!destinationParentRecord) return
      const destinationConnection = ConnectionHandler.getConnections(
        destinationParentRecord,
        toSection
          ? "ProductsSidebarList_NavSectionPaginationFragment__products"
          : "ProductsSidebarList_OrganizationPaginationFragment__topLevelProducts"
      )[0]
      if (!destinationConnection) return
      Relay.moveNodeBetweenConnections(
        sourceConnection,
        destinationConnection,
        productId,
        destination.index
      )
    }
  }

  async function handleNavSectionDragEnd(result: DropResult) {
    const { source, destination, draggableId } = result
    await reorderNavSection(
      {
        input: {
          navSectionId: draggableId,
          ordering: destination!.index,
        },
      },
      {
        optimisticUpdater: storeUpdater,
        updater: (store, { response }) => {
          if (!response.errors) storeUpdater(store)
        },
      }
    )
    function storeUpdater(store: RecordSourceSelectorProxy) {
      const organizationRecord = store.get(activeOrganization.id)
      if (!organizationRecord) return
      ConnectionHandler.getConnections(
        organizationRecord,
        "ProductsSidebar__productsNavSections"
      ).forEach((connection) => {
        Relay.reorderEdgeInConnection(connection, source.index, destination!.index)
      })
    }
  }
}

type ProductsSidebarProductsDragDropProps<T extends { id: string }> = {
  navSectionId?: GlobalID
  products: T[]
  children: (
    product: T,
    dragHandleProps: DraggableProvidedDragHandleProps | undefined,
    isDragging: boolean,
    index: number
  ) => ReactNode
  disabled: boolean
  emptyState: ReactNode
  showEmptyStateOnDrag: boolean
}

export function ProductsSidebarProductsDragDrop<T extends { id: string }>(
  props: ProductsSidebarProductsDragDropProps<T>
) {
  const { navSectionId, products, children, disabled, emptyState, showEmptyStateOnDrag } =
    props
  const { draggingId } = useContext(SidebarDragDropContext)

  return (
    <Droppable
      droppableId={navSectionId || TOP_LEVEL_PRODUCTS_DROPPABLE_ID}
      type={PRODUCTS_DROPPABLE_TYPE}
    >
      {(droppableProvided) => (
        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
          {products.length
            ? products.map((product, i) => (
                <Draggable
                  key={product.id}
                  draggableId={product.id}
                  index={i}
                  isDragDisabled={disabled}
                >
                  {(draggableProvided) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                    >
                      {children(
                        product,
                        draggableProvided.dragHandleProps,
                        product.id === draggingId,
                        i
                      )}
                    </div>
                  )}
                </Draggable>
              ))
            : renderEmptyState()}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  )

  function renderEmptyState() {
    if (showEmptyStateOnDrag && !draggingId) return null
    return emptyState
  }
}
