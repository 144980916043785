import { LabelFormFieldSkeleton } from "@/admin/labels/LabelFormField"
import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import { ExperienceSettingsFormStore } from "@/pricing/PricingFormFields"
import { generateReadablePricing } from "@/pricing/pricingUtils"
import { MembershipPlanExperiencePricingListItemFragment$key } from "@/product/settings/pricing/__generated__/MembershipPlanExperiencePricingListItemFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoText } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  form: ExperienceSettingsFormStore | PathwaySettingsFormStore
  membershipPlanKey: MembershipPlanExperiencePricingListItemFragment$key
  cta?: React.ReactNode
}

function MembershipPlanExperiencePricingListItem({
  testid = "MembershipPlanExperiencePricingListItem",
  form,
  membershipPlanKey,
  cta,
}: Props) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  const membershipPlan = useFragment<MembershipPlanExperiencePricingListItemFragment$key>(
    graphql`
      fragment MembershipPlanExperiencePricingListItemFragment on Product {
        id
        name
        cover
        membershipBenefits {
          totalCount
        }
        pricing {
          id
          amountCents
          frequency
          kind
        }
      }
    `,
    membershipPlanKey
  )

  return (
    <DiscoFormControl
      testid={testid}
      error={Boolean(form.errorsByField.pricing)}
      errorMessages={form.errorsByField.pricing}
      className={classes.form}
    >
      <div>
        {/* Header */}
        <div className={classes.header}>
          <div className={classes.details}>
            <DiscoImage
              className={classes.cover}
              src={membershipPlan.cover || defaultThumbnail}
            />

            <div>
              {/* Name */}
              <DiscoText variant={"body-md-600"}>{membershipPlan.name}</DiscoText>

              {/* Price */}
              {membershipPlan.pricing && (
                <DiscoText variant={"body-sm"} color={"text.secondary"}>
                  {generateReadablePricing(
                    membershipPlan.pricing,
                    activeOrganization.currency
                  )}
                </DiscoText>
              )}
            </div>
          </div>

          {Boolean(cta) && <div>{cta}</div>}
        </div>
      </div>
    </DiscoFormControl>
  )
}

const useStyles = makeUseStyles((theme) => ({
  form: {
    marginBottom: 0,
    padding: theme.spacing(1.5),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  details: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  cover: {
    height: "50px",
    display: "block",
    width: "auto",
    borderRadius: theme.measure.borderRadius.medium,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

export const MembershipPlanExperiencePricingListItemSkeleton = () => {
  const heights = [60]

  return (
    <div>
      {heights.map((height) => (
        <LabelFormFieldSkeleton key={height} height={height} width={350} />
      ))}
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(MembershipPlanExperiencePricingListItem),
  skeleton: MembershipPlanExperiencePricingListItemSkeleton,
})
