/**
 * @generated SignedSource<<97625c648f37641b3ae17845c2248f49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductApplicationStatus = "acceptance_failed" | "accepted" | "pending" | "rejected" | "%future added value";
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
export type ProductRegistrationType = "application" | "closed" | "open" | "waitlist" | "%future added value";
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductRegistrationSectionFragment$data = {
  readonly applicationQuestions: {
    readonly totalCount: number;
  };
  readonly id: string;
  readonly landingPageUrl: string | null;
  readonly organization: {
    readonly questions: {
      readonly totalCount: number;
    };
  };
  readonly registrationAvailability: ProductRegistrationAvailability;
  readonly registrationPricing: {
    readonly basePrice: number;
  } | null;
  readonly registrationType: ProductRegistrationType;
  readonly slug: string;
  readonly status: ProductStatus;
  readonly type: ProductType;
  readonly viewerApplication: {
    readonly id: string;
    readonly status: ProductApplicationStatus;
  } | null;
  readonly waitlistCtaLabel: string | null;
  readonly waitlistUrl: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "ProductRegistrationSectionFragment";
};
export type ProductRegistrationSectionFragment$key = {
  readonly " $data"?: ProductRegistrationSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductRegistrationSectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductRegistrationSectionFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landingPageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitlistUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitlistCtaLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApplication",
      "kind": "LinkedField",
      "name": "viewerApplication",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionNodeConnection",
      "kind": "LinkedField",
      "name": "applicationQuestions",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionNodeConnection",
          "kind": "LinkedField",
          "name": "questions",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RegistrationPricing",
      "kind": "LinkedField",
      "name": "registrationPricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "b65679c683360782bf52b12b87594d32";

export default node;
