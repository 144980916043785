import { CreateExperienceFormState } from "@/admin/experiences/create/CreateExperienceForm"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductRegistrationAvailability } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import FormStore from "@/core/form/store/FormStore"
import { ExperienceSettingsFormStore } from "@/pricing/PricingFormFields"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import ProductUtils from "@/product/util/productUtils"
import { DiscoFormControl } from "@disco-ui"
import DiscoRadioBox from "@disco-ui/radio/DiscoRadioBox"
import { RadioGroup } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import React from "react"

interface Props extends TestIDProps {
  form: FormStore<CreateExperienceFormState> | ExperienceSettingsFormStore
}

function ExperienceSettingsAccessRadioGroup(props: Props) {
  const { form, testid = "ExperienceSettingsAccessRadioGroupFormFields" } = props
  const membersLabel = useLabel("admin_member")
  const experienceLabel = useLabel("admin_experience")

  return (
    <>
      <DiscoFormControl
        label={
          <ExperienceSettingsLabel
            title={"Access"}
            tooltipContent={`Access to this ${experienceLabel.singular} is also dependent on the membership plans it is available on.`}
            sectionId={"experience_access"}
          />
        }
        sublabel={`Choose who can view the registration page and join this ${experienceLabel.singular}.`}
        error={Boolean(form.errorsByField.registrationAvailability)}
        errorMessages={form.errorsByField.registrationAvailability}
      >
        <RadioGroup
          onChange={handleAvailabilityChange}
          value={form.state.registrationAvailability}
          row
          style={{ gap: "12px" }}
        >
          {/* Public */}
          <DiscoRadioBox
            testid={`${testid}.radio.public`}
            value={"public"}
            icon={ProductUtils.getAvailabilityIcon("public")}
            title={"Public"}
            subtitle={`${membersLabel.singular} and anyone on the internet can join this ${experienceLabel.singular} through the registration page.`}
          />

          {/* Private / Members Only */}
          <DiscoRadioBox
            testid={`${testid}.radio.private`}
            value={"private"}
            icon={ProductUtils.getAvailabilityIcon("private")}
            title={ProductUtils.getAvailabilityLabel("private", membersLabel)}
            subtitle={`Only ${membersLabel.plural} can join this ${experienceLabel.singular} through the registration page.`}
          />

          {/* Hidden / Invite Only */}
          <DiscoRadioBox
            testid={`${testid}.radio.hidden`}
            value={"hidden"}
            icon={ProductUtils.getAvailabilityIcon("hidden")}
            title={ProductUtils.getAvailabilityLabel("hidden", membersLabel)}
            subtitle={`Only those invited can join this ${experienceLabel.singular} through an email invitation.`}
          />
        </RadioGroup>
      </DiscoFormControl>
    </>
  )

  function handleAvailabilityChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.registrationAvailability = event.target
      .value as ProductRegistrationAvailability
  }
}

export default observer(ExperienceSettingsAccessRadioGroup)
