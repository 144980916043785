/**
 * @generated SignedSource<<e307717bd8fe29e17b336940a588aad5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OrganizationRole = "admin" | "member" | "owner" | "%future added value";
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExperienceAdminsListItemFragment$data = {
  readonly id: string;
  readonly member: {
    readonly fullName: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
  };
  readonly organizationMembership: {
    readonly email: string | null;
    readonly role: OrganizationRole;
  };
  readonly product: {
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  };
  readonly role: ProductRole;
  readonly " $fragmentSpreads": FragmentRefs<"UnenrollProductMemberButtonFragment" | "UpdateProductRoleButtonFragment">;
  readonly " $fragmentType": "ExperienceAdminsListItemFragment";
};
export type ExperienceAdminsListItemFragment$key = {
  readonly " $data"?: ExperienceAdminsListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceAdminsListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperienceAdminsListItemFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "member",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarWithDetailsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "usePermissionsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "organizationMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UnenrollProductMemberButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateProductRoleButtonFragment"
    }
  ],
  "type": "ProductMembership",
  "abstractKey": null
};
})();

(node as any).hash = "52ae567dc93360e9c531f5991a68ba09";

export default node;
