/**
 * @generated SignedSource<<6f96f983f2ab75a200e24d60ffbdfe33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsWaitingRoomEnabledActiveProductFragment$data = {
  readonly startDate: string | null;
  readonly waitingRoomEndsAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "useIsWaitingRoomEnabledActiveProductFragment";
};
export type useIsWaitingRoomEnabledActiveProductFragment$key = {
  readonly " $data"?: useIsWaitingRoomEnabledActiveProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsWaitingRoomEnabledActiveProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsWaitingRoomEnabledActiveProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitingRoomEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "ccb1c44c44add29e05fe767b3bb875a4";

export default node;
