/**
 * @generated SignedSource<<f41db0652b139036b108d000afe6f5f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductsSidebarList_OrganizationPaginationFragment$data = {
  readonly id: string;
  readonly topLevelProducts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly slug: string;
        readonly status: ProductStatus;
        readonly type: ProductType;
        readonly viewerMembership: {
          readonly id: string;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"MyExperiencesListItemFragment" | "PathwaySidebarItemFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "ProductsSidebarList_OrganizationPaginationFragment";
};
export type ProductsSidebarList_OrganizationPaginationFragment$key = {
  readonly " $data"?: ProductsSidebarList_OrganizationPaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductsSidebarList_OrganizationPaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "topLevelProducts"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ProductsSidebarList_OrganizationPaginationQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "ProductsSidebarList_OrganizationPaginationFragment",
  "selections": [
    (v1/*: any*/),
    {
      "alias": "topLevelProducts",
      "args": [
        {
          "kind": "Literal",
          "name": "noNavSection",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "nav"
        },
        {
          "kind": "Literal",
          "name": "types",
          "value": [
            "course",
            "pathway"
          ]
        }
      ],
      "concreteType": "ProductNodeConnection",
      "kind": "LinkedField",
      "name": "__ProductsSidebarList_OrganizationPaginationFragment__topLevelProducts_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductMembership",
                  "kind": "LinkedField",
                  "name": "viewerMembership",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MyExperiencesListItemFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PathwaySidebarItemFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__ProductsSidebarList_OrganizationPaginationFragment__topLevelProducts_connection(noNavSection:true,orderBy:\"nav\",types:[\"course\",\"pathway\"])"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "41032b07151332544e7ca28620e08844";

export default node;
