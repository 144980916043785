/**
 * @generated SignedSource<<2a58a3b337dbfd16e156f18a97af5dfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RegistrationExperienceDetailsFormFragment$data = {
  readonly cover: string;
  readonly id: string;
  readonly richEditorCheckoutDescription: string | null;
  readonly type: ProductType;
  readonly " $fragmentType": "RegistrationExperienceDetailsFormFragment";
};
export type RegistrationExperienceDetailsFormFragment$key = {
  readonly " $data"?: RegistrationExperienceDetailsFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RegistrationExperienceDetailsFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegistrationExperienceDetailsFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorCheckoutDescription",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "44bb4e14691f55448fe895ded362a154";

export default node;
