import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isDiscoDomain } from "@/core/route/util/routeUtils"
import { stopImpersonatingTestUser } from "@/product/util/hook/useInitImpersonateTestUser"
import { isE2ETest } from "@utils/e2e"
import { getDevice, isWebView } from "@utils/webView/webViewUtils"
import axios, { AxiosRequestConfig } from "axios"
import * as crypto from "crypto"
import { createSigner, httpbis } from "http-message-signatures"
import { unstable_batchedUpdates as batchedUpdates } from "react-dom"
import { Environment as ReactRelayEnvironment } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import {
  GraphQLResponse,
  Network,
  RecordSource,
  FetchFunction as RelayFetchFunction,
  Environment as RelayRuntimeEnvironment,
  Store,
  TaskScheduler,
} from "relay-runtime"
import relayDefaultHandlerProvider from "relay-runtime/lib/handlers/RelayDefaultHandlerProvider"
import { v4 as uuidv4 } from "uuid"

const SESSION_EXPIRED_ERROR = "Context creation failed: Invalid session cookie"
export const INVALID_USER_LINK_KEY_ERROR = "Invalid user link."

/** Execute a fetch request with the provided query and variables. */
const fetchRelay: RelayFetchFunction = async (params, variables) => {
  const requestID = uuidv4().toString()
  const endpoint = isDiscoDomain(window.location.host)
    ? GRAPHQL_URL
    : `https://${window.location.host}/graphql`

  const headers: Record<string, string> = {
    "X-Disco-Request-ID": requestID,
    "X-Disco-Platform": isWebView() ? "mobile" : "web",
    "X-Disco-Device": getDevice(),
  }

  if (isE2ETest()) {
    headers["X-E2E-Test"] = "1"
  }
  if (isWebView()) {
    headers["X-WebView"] = "1"
  }
  if (window.discoViewAs) {
    headers["X-Disco-View-As"] = window.discoViewAs
  }
  if (window.discoUlKey) {
    headers["X-UL-Key"] = window.discoUlKey
  }

  const body = {
    query: params.text,
    variables,
  }
  const url = `${endpoint}?requestID=${requestID}`

  // Generate the message digest
  const data = Buffer.from(JSON.stringify(body))
  const contentLength = Buffer.byteLength(data).toString()
  const contentDigest = crypto.createHash("sha512").update(data).digest("base64")

  // Generate the signed request
  const key = createSigner(SKEY, "hmac-sha256", "shared-secret")
  const signedRequest = await httpbis.signMessage<
    AxiosRequestConfig & {
      method: string
      url: string
      headers: Record<string, string | string[]>
    }
  >(
    {
      key,
      fields: [
        "x-disco-request-id",
        "@method",
        "@path",
        "content-digest",
        "content-length",
      ],
      paramValues: {
        created: null,
      },
    },
    {
      method: "POST",
      url,
      headers: {
        ...headers,
        "Content-Type": "application/json",
        "Content-Digest": `sha-512=:${contentDigest}:`,
        "Content-Length": contentLength,
      },
      withCredentials: true,
      data,
    }
  )

  // Remove the signature input, header
  delete signedRequest.headers["Signature-Input"]
  // Browser automatically sets this header and logs an error if set manually
  delete signedRequest.headers["Content-Length"]
  try {
    const res = await axios.request<GraphQLResponse>(signedRequest)
    return res.data
  } catch (error: any) {
    const errorMessage = error?.response?.data?.errors?.[0]?.message

    if (errorMessage === SESSION_EXPIRED_ERROR) {
      // Handle session expiry gracefully by redirecting to login
      window.location.href = ROUTE_NAMES.AUTHENTICATION.LOGIN
    } else if (errorMessage === INVALID_USER_LINK_KEY_ERROR) {
      stopImpersonatingTestUser()
    }
    throw error
  }
}

// Use React's batchedUpdates to ensure that the parent component will update first before a child component.
// Reference: https://github.com/facebook/relay/issues/3514
const RelayScheduler: TaskScheduler = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cancel: () => {},
  schedule: (task) => {
    batchedUpdates(task)
    return ""
  },
}

/** Singleton instance of Relay Environment with a cache */
const RelayEnvironment = new RelayRuntimeEnvironment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource()),
  handlerProvider: (handle) => {
    switch (handle) {
      case "connection":
        return ConnectionHandler
      default:
        return relayDefaultHandlerProvider(handle)
    }
  },
  scheduler: RelayScheduler,
  // Type hack to make the environment compatible with react-relay and relay-runtime
  // without the 12.0.0 version of @types/react-relay available.
}) as RelayRuntimeEnvironment & ReactRelayEnvironment

export default RelayEnvironment
