/**
 * @generated SignedSource<<e69349d2ebf899384d6782a827678daf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ReportingUserActivityKind = "attended" | "bookmark_click" | "channel_message_reaction" | "channel_message_sent" | "clicked" | "commented" | "completed" | "created" | "direct_message_reaction" | "direct_message_sent" | "reaction" | "search" | "search_hit" | "%future added value";
export type TrackUserActivityInput = {
  entityId: string;
  kind: ReportingUserActivityKind;
  metadata?: ReportingUserActivityMetadataInput | null;
  organizationId: string;
  productId?: string | null;
};
export type ReportingUserActivityMetadataInput = {
  search?: string | null;
};
export type useTrackUserActivityMutation$variables = {
  input: TrackUserActivityInput;
};
export type useTrackUserActivityMutation$data = {
  readonly trackUserActivity: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type useTrackUserActivityMutation = {
  response: useTrackUserActivityMutation$data;
  variables: useTrackUserActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TrackUserActivityResponse",
    "kind": "LinkedField",
    "name": "trackUserActivity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useTrackUserActivityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useTrackUserActivityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3334c0cf500b1dba92c20cf839b96d14",
    "id": null,
    "metadata": {},
    "name": "useTrackUserActivityMutation",
    "operationKind": "mutation",
    "text": "mutation useTrackUserActivityMutation(\n  $input: TrackUserActivityInput!\n) {\n  trackUserActivity(input: $input) {\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2b8ae3d280f6c3dac934b4c4aad767b";

export default node;
