import useIsExplorePageShown from "@/admin/community/product-list/hooks/useIsExporePageShown"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useProductSlug } from "@/core/route/util/routeUtils"
import MyExperiencesListItem from "@/organization/common/sidebar/my-experiences-list/MyExperiencesListItem"
import PathwaySidebarItem from "@/organization/common/sidebar/pathway/PathwaySidebarItem"
import { ProductsSidebarProductsDragDrop } from "@/product/sidebar/ProductsSidebarDragDropProvider"
import { ProductsSidebarList_NavSectionPaginationFragment$key } from "@/product/sidebar/__generated__/ProductsSidebarList_NavSectionPaginationFragment.graphql"
import { ProductsSidebarList_NavSectionPaginationQuery } from "@/product/sidebar/__generated__/ProductsSidebarList_NavSectionPaginationQuery.graphql"
import { ProductsSidebarList_OrganizationPaginationFragment$key } from "@/product/sidebar/__generated__/ProductsSidebarList_OrganizationPaginationFragment.graphql"
import { ProductsSidebarList_OrganizationPaginationQuery } from "@/product/sidebar/__generated__/ProductsSidebarList_OrganizationPaginationQuery.graphql"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoSideBarItemSkeleton, DiscoText } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { useTheme } from "@material-ui/core"
import { range } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, usePaginationFragment } from "react-relay"

type Props = TestIDProps & {
  organizationKey?: ProductsSidebarList_OrganizationPaginationFragment$key | null
  navSectionKey?: ProductsSidebarList_NavSectionPaginationFragment$key | null
}

const PRODUCTS_PER_PAGE = 10

export default function ProductsSidebarList(props: Props) {
  const { organizationKey, navSectionKey, testid = "ProductsSidebarList" } = props
  const activeOrganization = useActiveOrganization()!
  const canReorder = activeOrganization.viewerPermissions.has("products.manage")
  const isAdminViewingAsMember = useIsAdminViewingAsMember()
  const activeProductSlug = useProductSlug()
  const classes = useStyles()
  const productLabel = useLabel("admin_experience")
  const theme = useTheme()
  const isExplorePageShown = useIsExplorePageShown()

  // Top level products with their pagination
  const topLevelPagination = usePaginationFragment<
    ProductsSidebarList_OrganizationPaginationQuery,
    ProductsSidebarList_OrganizationPaginationFragment$key
  >(
    graphql`
      fragment ProductsSidebarList_OrganizationPaginationFragment on Organization
      @refetchable(queryName: "ProductsSidebarList_OrganizationPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 20 }
        after: { type: "String" }
      ) {
        id
        topLevelProducts: products(
          types: [course, pathway]
          noNavSection: true
          orderBy: nav
          first: $first
          after: $after
        )
          @connection(
            key: "ProductsSidebarList_OrganizationPaginationFragment__topLevelProducts"
          ) {
          edges {
            node {
              id
              slug
              status
              type
              viewerMembership {
                id
              }
              ...MyExperiencesListItemFragment
              ...PathwaySidebarItemFragment
            }
          }
        }
      }
    `,
    organizationKey || null
  )
  const organization = topLevelPagination?.data

  // Nav section level products with their pagination
  const navSectionPagination = usePaginationFragment<
    ProductsSidebarList_NavSectionPaginationQuery,
    ProductsSidebarList_NavSectionPaginationFragment$key
  >(
    graphql`
      fragment ProductsSidebarList_NavSectionPaginationFragment on NavSection
      @refetchable(queryName: "ProductsSidebarList_NavSectionPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
      ) {
        id
        products(first: $first, after: $after)
          @connection(key: "ProductsSidebarList_NavSectionPaginationFragment__products") {
          edges {
            node {
              id
              slug
              status
              type
              ...MyExperiencesListItemFragment
              ...PathwaySidebarItemFragment
            }
          }
        }
      }
    `,
    navSectionKey || null
  )
  const navSection = navSectionPagination?.data

  // Use either the organization or nav section fragment's pagination + data
  const { loadNext, hasNext, isLoadingNext } = organization
    ? topLevelPagination
    : navSectionPagination
  let products = organization
    ? Relay.connectionToArray(organization.topLevelProducts).filter(
        // Hide products viewer isn't a member of if not an org admin
        (p) => canReorder || p.viewerMembership
      )
    : Relay.connectionToArray(navSection?.products)

  // Hide draft products when the admin is viewing as member (unless inside it)
  if (isAdminViewingAsMember) {
    products = products.filter((p) => {
      if (activeProductSlug && p.slug === activeProductSlug) return true
      return p.status === "published"
    })
  }

  if (!products.length && !isExplorePageShown)
    return (
      <div className={organization ? classes.topLevel : undefined} data-testid={testid}>
        <DiscoText
          variant={"body-md"}
          color={
            theme.palette.type === "dark" ? "groovy.onDark.300" : "groovy.neutral.400"
          }
          marginTop={1}
          marginBottom={1}
        >
          {`No ${productLabel.plural} yet.`}
        </DiscoText>
      </div>
    )

  return (
    <>
      {organization && products.length > 0 && isExplorePageShown && (
        <DiscoDivider marginTop={1.5} />
      )}
      <div className={organization ? classes.topLevel : undefined} data-testid={testid}>
        <ProductsSidebarProductsDragDrop
          navSectionId={navSection?.id}
          disabled={!canReorder}
          products={products}
          emptyState={getEmptyState()}
          showEmptyStateOnDrag={!navSection?.id}
        >
          {(product, dragHandleProps, isDragging, i) => (
            <>
              {product.type === "course" ? (
                <MyExperiencesListItem
                  testid={`${testid}.${product.slug}`}
                  productKey={product}
                  dragHandleProps={dragHandleProps}
                  isDragging={isDragging}
                />
              ) : (
                <PathwaySidebarItem
                  testid={`${testid}.${product.slug}`}
                  productKey={product}
                  dragHandleProps={dragHandleProps}
                  isDragging={isDragging}
                />
              )}
              {i === products.length - 1 && hasNext && (
                <DiscoScrolledIntoView
                  onScrolledIntoView={() => loadNext(PRODUCTS_PER_PAGE)}
                  isLoading={isLoadingNext}
                  skeleton={
                    <>
                      {range(PRODUCTS_PER_PAGE).map((k) => (
                        <DiscoSideBarItemSkeleton key={k} />
                      ))}
                    </>
                  }
                />
              )}
            </>
          )}
        </ProductsSidebarProductsDragDrop>
      </div>
    </>
  )

  function getEmptyState() {
    if (!canReorder) return null
    if (navSection)
      return (
        <DiscoText
          variant={"body-sm"}
          color={
            theme.palette.type === "dark" ? "groovy.onDark.300" : "groovy.neutral.300"
          }
          marginLeft={2}
          marginTop={1}
          marginBottom={1}
        >
          {`No ${productLabel.plural} here yet.`}
        </DiscoText>
      )
    // Show an empty section when dragging so products can be dragged into it
    return (
      <DiscoDivider
        marginTop={1.5}
        marginBottom={1.5}
        className={classes.emptyStateDivider}
      />
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  topLevel: {
    padding: theme.spacing(0, 2),
    marginTop: theme.spacing(1.5),
  },
  emptyStateDivider: {
    marginLeft: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(4)}px)`,
  },
}))
