/**
 * @generated SignedSource<<df2092393da651d3253523b1f8763751>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateUserProfileInput = {
  avatar?: string | null;
  bio?: string | null;
  cover?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  timezone?: string | null;
  webFormSubmission?: CreateWebFormSubmissionInput | null;
};
export type CreateWebFormSubmissionInput = {
  answers?: ReadonlyArray<WebFormAnswerInput> | null;
  contentUsageId?: string | null;
  webFormRevisionId: string;
};
export type WebFormAnswerInput = {
  body?: string | null;
  isSkipped?: boolean | null;
  selectedOptions?: ReadonlyArray<string> | null;
  webFormQuestionId: string;
};
export type ProductRegistrationProfileStepMutation$variables = {
  input: UpdateUserProfileInput;
};
export type ProductRegistrationProfileStepMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
  };
};
export type ProductRegistrationProfileStepMutation = {
  response: ProductRegistrationProfileStepMutation$data;
  variables: ProductRegistrationProfileStepMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductRegistrationProfileStepMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserProfileResponse",
        "kind": "LinkedField",
        "name": "updateProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductRegistrationProfileStepMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserProfileResponse",
        "kind": "LinkedField",
        "name": "updateProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc3f6e5e45cdfab31fdd733f7713bdbd",
    "id": null,
    "metadata": {},
    "name": "ProductRegistrationProfileStepMutation",
    "operationKind": "mutation",
    "text": "mutation ProductRegistrationProfileStepMutation(\n  $input: UpdateUserProfileInput!\n) {\n  response: updateProfile(input: $input) {\n    node {\n      firstName\n      lastName\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9fe1d58d86ad93535ac1e8872fbbe013";

export default node;
