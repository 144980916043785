import { useLogout } from "@/authentication/logout/util/useLogout"
import {
  isSupportedCheckoutProduct,
  useStartEntityCheckout,
} from "@/checkout/utils/CheckoutUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel, useLabels } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import MembershipPlanSelectButton from "@/membership-plan/register/MembershipPlanSelectButton"
import MembershipPlanSelectSection from "@/membership-plan/register/MembershipPlanSelectSection"
import { ProductRegistrationSectionFragment$key } from "@/product/register/__generated__/ProductRegistrationSectionFragment.graphql"
import { ProductRegistrationSectionOccurrenceFragment$key } from "@/product/register/__generated__/ProductRegistrationSectionOccurrenceFragment.graphql"
import { ProductRegistrationSectionQuery } from "@/product/register/__generated__/ProductRegistrationSectionQuery.graphql"
import { ProductApplicationStatus } from "@/product/register/steps/__generated__/ProductRegistrationApplyStep_SubmitApplicationMutation.graphql"
import { ProductRegistrationType } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoButtonColor,
  DiscoButtonSkeleton,
  DiscoIcon,
  DiscoIconKinds,
  DiscoText,
  DiscoTextButton,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { useEffect, useState } from "react"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory, useRouteMatch } from "react-router-dom"
import { graphql } from "relay-runtime"
import CancelMembershipPlanButton from "../../membership-plan/cancel/button/CancelMembershipPlanButton"

export type RegistrationStatus =
  | "anonymous"
  | "unregistered"
  | "registered"
  | "applied"
  | "waitlist"
  | "unavailableOnPlan"
  | "alreadyOnPlan"

interface Props extends TestIDProps {
  experienceKey: ProductRegistrationSectionFragment$key
  occurrenceKey?: ProductRegistrationSectionOccurrenceFragment$key | null
  setStartRegistration: React.Dispatch<React.SetStateAction<boolean>>
  onPage?: boolean
}

function ProductRegistrationSection({
  experienceKey,
  occurrenceKey,
  setStartRegistration,
  onPage = false,
  testid = `ProductRegistrationSection`,
}: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const match = useRouteMatch<{ productSlug?: string; occurrenceId?: string }>()
  const drawer = useGlobalDrawer("registration")
  const { drawerRegistrationOccurrenceId } = drawer.params
  const experienceLabel = useLabel("experience")
  const isMobile = useIsMobile()
  const isMobileDrawer = !onPage && isMobile
  const { authUser } = useAuthUser()
  const isSwitchingPlans = Boolean(
    activeOrganization?.viewerMembershipPlan && activeOrganization?.viewerMembership
  )
  const history = useHistory()

  const { membership } = useLazyLoadQuery<ProductRegistrationSectionQuery>(
    graphql`
      query ProductRegistrationSectionQuery($id: ID!) {
        membership: node(id: $id) {
          ... on OrganizationMembership {
            id
            email
            member {
              id
              fullName
              ...ProfileAvatarWithDetailsFragment
            }
            productMemberships(productType: "membership_plan") {
              totalCount
              edges {
                node {
                  id
                  product {
                    name
                    isFreeMembership
                    ...CancelMembershipPlanButtonFragment
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.viewerMembership?.id || "",
    },
    { fetchPolicy: "network-only" }
  )

  const product = useFragment<ProductRegistrationSectionFragment$key>(
    graphql`
      fragment ProductRegistrationSectionFragment on Product {
        id
        status
        registrationType
        registrationAvailability
        landingPageUrl
        slug
        type
        waitlistUrl
        waitlistCtaLabel
        viewerApplication {
          id
          status
        }
        applicationQuestions {
          totalCount
        }
        organization {
          questions {
            totalCount
          }
        }
        registrationPricing {
          basePrice
        }
        ...usePermissionsFragment
      }
    `,
    experienceKey
  )
  const permissions = usePermissions(product)
  const labels = useLabels()
  const { startEntityCheckout } = useStartEntityCheckout(product)

  const isCommunityEvent = product.type === "community_event"
  const isPathway = product.type === "pathway"
  const isMembershipPlan = product.type === "membership_plan"
  const isCourse = product.type === "course"

  const occurrence = useFragment<ProductRegistrationSectionOccurrenceFragment$key>(
    graphql`
      fragment ProductRegistrationSectionOccurrenceFragment on Occurrence {
        id
        atCapacity
      }
    `,
    occurrenceKey || null
  )

  const occurrenceId =
    occurrence?.id || match.params.occurrenceId || drawerRegistrationOccurrenceId

  const atCapacity = occurrence && occurrence.atCapacity
  const isCommunityAdmin = permissions.has("registration.manage")
  const hasApplication = Boolean(product.applicationQuestions.totalCount)

  const [registrationStatus, setRegistrationStatus] = useState<RegistrationStatus>(
    getRegistrationStatus()
  )

  const planMemberships = Relay.connectionToArray(membership?.productMemberships)
  const currentPlan = planMemberships.length ? planMemberships[0].product : null

  const logout = useLogout()

  useEffect(() => {
    setRegistrationStatus(getRegistrationStatus())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, activeProduct, activeOrganization])

  if (!product) return null

  return (
    <div data-testid={"ProductRegistration.container"}>
      {renderRegistration()}
      {renderAdminButton()}
    </div>
  )

  function getRegistrationStatus(): RegistrationStatus {
    const isAdminViewingPathwayAsMember =
      isPathway && activeOrganization?.isAdminViewingAsMember

    if (
      (isCommunityEvent && activeProduct?.viewerMembership?.id) ||
      // Don't show org admins registration button when viewing as memeber
      isAdminViewingPathwayAsMember
    )
      return "registered"
    if (isCommunityEvent && activeOrganization?.viewerMembership?.id)
      return "unregistered"
    if (isCommunityEvent && !activeOrganization?.viewerMembership?.id) return "anonymous"
    if (!product.registrationPricing && !isCommunityAdmin) return "unavailableOnPlan"
    if (activeProduct?.viewerMembership?.id && product?.type !== "membership_plan")
      return "registered"
    if (product.registrationType === "waitlist" && product.waitlistUrl) return "waitlist"
    if (product.viewerApplication?.id) return "applied"
    if (
      membership?.productMemberships?.totalCount &&
      activeProduct?.type === "membership_plan"
    )
      return "alreadyOnPlan"
    if (activeOrganization?.viewerMembership?.id) return "unregistered"
    return "anonymous"
  }

  function renderAdminButton() {
    if (!permissions.has("registration.manage")) return null
    if (registrationStatus === "registered") return null
    if (isMembershipPlan) return null
    return (
      <DiscoTooltip
        content={`As a Community Admin, you can view the ${
          occurrenceId
            ? "Event"
            : isCourse
            ? experienceLabel.singular
            : labels.pathway.singular
        } without having to register.`}
      >
        <DiscoButton
          color={"grey"}
          variant={"outlined"}
          to={{
            pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
              productSlug: product.slug,
            }),
            ...(occurrenceId && {
              search: setSearchParams<GlobalDrawerParams<"event">>("", {
                drawerOccurrenceId: occurrenceId,
                drawerEventTab: "details",
              }),
            }),
          }}
          width={"100%"}
          className={classes.adminViewButton}
          data-testid={`${testid}.admin-view-link`}
          // Needs to be _top or else the page will infinitely re-render
          // top targets the outter-most frame aka: window, see https://stackoverflow.com/a/63759718
          target={drawer.isOpen ? "_self" : "_top"}
        >
          {occurrenceId
            ? "View Event"
            : `View ${isCourse ? experienceLabel.singular : labels.pathway.singular}`}
        </DiscoButton>
      </DiscoTooltip>
    )
  }

  function renderRegistration() {
    switch (registrationStatus) {
      case "anonymous":
      default:
        return (
          <>
            <DiscoText variant={"body-md-600"}>{"Registration"}</DiscoText>
            <DiscoText variant={"body-md"} color={"text.secondary"} paddingBottom={2}>
              {getRegistrationTypeText(product.registrationType)}
            </DiscoText>
            <DiscoButton
              data-testid={`${testid}.register-now`}
              onClick={handleStartRegistration}
              width={"100%"}
            >
              {"Register Now"}
            </DiscoButton>
            {product.landingPageUrl && (
              <DiscoButton
                to={product.landingPageUrl!}
                width={"100%"}
                color={"grey"}
                variant={"outlined"}
                className={classes.landingPage}
                data-testid={`${testid}.landing-page-link`}
              >
                {"Visit Landing Page"}
              </DiscoButton>
            )}
          </>
        )
      case "unregistered": {
        const RegistrationButton = ({ disabled }: { disabled?: boolean }) =>
          atCapacity ? (
            <DiscoButton disabled color={"grey"} variant={"outlined"} width={"100%"}>
              {"At Capacity"}
            </DiscoButton>
          ) : (
            <DiscoButton
              disabled={disabled}
              onClick={handleStartRegistration}
              width={"100%"}
              data-testid={`${testid}.register-now`}
            >
              {hasApplication
                ? "Apply Now"
                : isMembershipPlan
                ? isSwitchingPlans
                  ? "Switch Plan"
                  : "Register Now"
                : "Register Now"}
            </DiscoButton>
          )
        return (
          <>
            {renderUserDetails()}

            {!isMobileDrawer && (
              <DiscoText variant={"body-md"} color={"text.secondary"} marginBottom={2}>
                {"or "}
                <span>
                  <DiscoTextButton
                    onClick={logout}
                    textVariant={"body-md"}
                    color={theme.palette.primary.main}
                    className={classes.signOut}
                    testid={`${testid}.signout`}
                  >
                    {"Sign Out"}
                  </DiscoTextButton>
                </span>
                {" to register under a different account."}
              </DiscoText>
            )}

            {isCommunityAdmin ? (
              <DiscoTooltip
                content={
                  isMembershipPlan
                    ? "Admin cannot select membership plans."
                    : isPathway
                    ? "Admins can view and operate Pathways without registering."
                    : `Admins can view ${labels.admin_experience.plural} or add themselves as a ${labels.product_admin.singular} or ${labels.product_instructor.singular}.`
                }
              >
                <span>
                  <RegistrationButton disabled />
                </span>
              </DiscoTooltip>
            ) : (
              <RegistrationButton />
            )}
          </>
        )
      }
      case "alreadyOnPlan":
        if (!currentPlan) return null
        return (
          <>
            <div className={classes.currentPlan}>
              <DiscoText
                variant={"body-md"}
                color={"text.secondary"}
                paddingBottom={2}
                display={"inline"}
              >
                {`Current plan: `}
              </DiscoText>
              <DiscoText paddingBottom={2} display={"inline"} variant={"body-md-700"}>
                {currentPlan?.name}
              </DiscoText>
            </div>

            {membership && membership.member && (
              <div className={classes.avatar}>
                <ProfileAvatarWithDetails
                  userKey={membership.member}
                  details={membership.email}
                  titleVariant={"body-md-500"}
                  subtitleVariant={"body-sm"}
                  truncateName={2}
                />
              </div>
            )}

            <div className={classes.planButtons}>
              {/* Upgrade from the free plan */}
              <MembershipPlanSelectButton>
                {(buttonProps) => (
                  <DiscoButton
                    {...buttonProps}
                    color={"grey"}
                    variant={"outlined"}
                    width={"100%"}
                    onClick={handleStartRegistration}
                    testid={"ProductRegistrationSection.change-plan"}
                  >
                    {"Change Plan"}
                  </DiscoButton>
                )}
              </MembershipPlanSelectButton>

              <CancelMembershipPlanButton membershipPlanKey={currentPlan}>
                {(buttonProps) => (
                  <DiscoButton {...buttonProps} color={"error"} width={"100%"}>
                    {"Cancel Plan"}
                  </DiscoButton>
                )}
              </CancelMembershipPlanButton>
            </div>
          </>
        )
      case "registered":
        return (
          <>
            {renderUserDetails()}

            {/* If the product being viewed is a membership plan */}
            {activeProduct?.type === "membership_plan" ||
            product?.type === "membership_plan" ? (
              <div className={classes.planButtons}>
                <MembershipPlanSelectButton>
                  {(buttonProps) => (
                    <DiscoButton
                      {...buttonProps}
                      color={"grey"}
                      variant={"outlined"}
                      width={"100%"}
                      testid={"ProductRegistrationSection.change-plan"}
                    >
                      {"Change Plan"}
                    </DiscoButton>
                  )}
                </MembershipPlanSelectButton>

                <CancelMembershipPlanButton membershipPlanKey={currentPlan!}>
                  {(buttonProps) => (
                    <DiscoButton {...buttonProps} color={"error"} width={"100%"}>
                      {"Cancel Plan"}
                    </DiscoButton>
                  )}
                </CancelMembershipPlanButton>
              </div>
            ) : (
              <DiscoButton
                to={{
                  pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                    productSlug: product.slug,
                  }),
                  ...(occurrenceId && {
                    search: setSearchParams<GlobalDrawerParams<"event">>("", {
                      drawerOccurrenceId: occurrenceId,
                      drawerEventTab: "details",
                    }),
                  }),
                }}
                width={"100%"}
                data-testid={`${testid}.overview-link`}
                // Needs to be _top or else the page will infinitely re-render
                // top targets the outter-most frame aka: window, see https://stackoverflow.com/a/63759718
                target={drawer.isOpen ? "_self" : "_top"}
              >
                {occurrenceId
                  ? "View Event"
                  : `View ${
                      isCourse ? experienceLabel.singular : labels.pathway.singular
                    }`}
              </DiscoButton>
            )}
          </>
        )
      case "applied":
        return (
          <>
            {renderUserDetails()}

            {!isMobileDrawer && (
              <DiscoText variant={"body-md"} color={"text.secondary"} marginBottom={2}>
                {"or "}
                <span>
                  <DiscoTextButton
                    onClick={logout}
                    textVariant={"body-md"}
                    color={theme.palette.primary.main}
                    className={classes.signOut}
                    testid={`${testid}.signout`}
                  >
                    {"Sign Out"}
                  </DiscoTextButton>
                </span>
                {" to register under a different account."}
              </DiscoText>
            )}

            {product.viewerApplication &&
              renderApplicationButton(product.viewerApplication.status)}
          </>
        )
      case "waitlist":
        return (
          <DiscoButton
            to={product.waitlistUrl!}
            target={"_blank"}
            width={"100%"}
            data-testid={`${testid}.join-the-waitlist`}
          >
            {product.waitlistCtaLabel || "Join Waitlist"}
          </DiscoButton>
        )
      case "unavailableOnPlan":
        if (isCommunityEvent) return null
        if (
          activeOrganization?.checkoutVersion === "stripe_acacia" &&
          isSupportedCheckoutProduct({
            checkoutVersion: activeOrganization.checkoutVersion,
            productType: product.type,
          })
        ) {
          // If the user does not exist, they must go through the initial registration process
          // so they can access the checkout page where they can select a membership plan
          if (!authUser?.id) {
            return (
              <>
                <DiscoText variant={"body-md-600"}>{"Registration"}</DiscoText>
                <DiscoText variant={"body-md"} color={"text.secondary"} paddingBottom={2}>
                  {getRegistrationTypeText(product.registrationType)}
                </DiscoText>
                <DiscoButton
                  data-testid={`${testid}.register-now`}
                  onClick={handleStartAnonymousRegistration}
                  width={"100%"}
                >
                  {"Register Now"}
                </DiscoButton>
              </>
            )
          }

          return (
            <div className={classes.planSelection}>
              <DiscoTag
                name={"Membership Required"}
                color={theme.palette.groovy.blue[400]}
                backgroundColor={theme.palette.groovy.blue[100]}
                marginBottom={1.5}
              />

              <DiscoText
                variant={"body-md-600"}
                marginBottom={0.5}
              >{`Select Membership Plan`}</DiscoText>

              <DiscoText variant={"body-md"} color={"text.secondary"} marginBottom={1.5}>
                {`You don't currently have access to this ${experienceLabel.singular}. Select a membership plan to get access.`}
              </DiscoText>

              <DiscoButton
                onClick={() => startEntityCheckout({ forcePlanSelection: true })}
                className={classes.selectPlanButton}
              >
                {"Register & Select Plan"}
              </DiscoButton>
            </div>
          )
        }
        return <MembershipPlanSelectSection className={classes.selectPlan} />
    }
  }

  function handleStartAnonymousRegistration() {
    history.replace({ state: { ignorePriceOnPlan: true } })
    setStartRegistration(true)
  }

  function renderUserDetails() {
    return (
      <>
        <DiscoText
          variant={"body-md"}
          color={"text.secondary"}
          testid={"ProductRegistrationSection.message"}
        >
          {"You're logged in and registering as"}
        </DiscoText>

        {!isMobileDrawer && authUser && (
          <div className={classes.avatar}>
            <ProfileAvatarWithDetails
              userKey={authUser}
              details={authUser.email}
              titleVariant={"body-md-500"}
              subtitleVariant={"body-sm"}
              truncateName={2}
            />
          </div>
        )}
      </>
    )
  }

  function renderApplicationButton(status?: ProductApplicationStatus | null) {
    let label = ""
    let color: DiscoButtonColor = "primary"
    let testId = ""
    let icon: DiscoIconKinds | null = null
    let disabled = false
    let onClick

    switch (status) {
      case "rejected":
        label = "Rejected"
        color = "error"
        testId = `${testid}.rejected`
        disabled = true
        break
      case "pending":
        label = "Under Review"
        color = "warning"
        icon = "time"
        testId = `${testid}.under-review`
        disabled = true
        break
      case "accepted":
      default:
        label = "Register"
        color = "primary"
        testId = `${testid}.accepted`
        onClick = () => startEntityCheckout()
        break
    }

    return (
      <DiscoButton
        leftIcon={icon ? <DiscoIcon icon={icon} /> : undefined}
        color={color}
        width={"100%"}
        data-testid={testId}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </DiscoButton>
    )
  }

  function getRegistrationTypeText(type: ProductRegistrationType) {
    switch (type) {
      case "closed":
        return "Registration is closed"
      case "waitlist":
        return "Join the waitlist"
      case "open":
      case "application":
      default:
        return "Open for new registrations"
    }
  }

  function handleStartRegistration() {
    setStartRegistration(true)
  }
}

const useStyles = makeUseStyles((theme) => ({
  landingPage: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    border: theme.palette.constants.borderSmall,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(2.5),
    margin: theme.spacing(2, 0),
  },
  signOut: {
    paddingBottom: theme.spacing(0.25),
  },
  adminViewButton: {
    marginTop: theme.spacing(1),
  },
  planButtons: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  currentPlan: {
    paddingBottom: theme.spacing(2),
  },
  selectPlan: {
    border: "none",
    padding: 0,
    marginTop: 0,
  },
  planSelection: {
    border: theme.palette.constants.borderSmall,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(2.5),
    margin: theme.spacing(-3),
  },
  selectPlanButton: {
    width: "100%",
  },
}))

export const ProductRegistrationSectionSkeleton: React.FC = () => {
  return (
    <>
      <DiscoTextSkeleton width={"75%"} height={"40px"} />
      <DiscoTextSkeleton marginBottom={1} width={"100%"} />
      <DiscoButtonSkeleton width={"100%"} />
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: ProductRegistrationSection,
  skeleton: ProductRegistrationSectionSkeleton,
})
