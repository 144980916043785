/**
 * @generated SignedSource<<02febc49f0483fdf08318dbbe22245f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductMemberMultiSelectQuery$variables = {
  id: string;
};
export type ProductMemberMultiSelectQuery$data = {
  readonly product: {
    readonly productMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly email: string | null;
          readonly id: string;
          readonly member: {
            readonly fullName: string;
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemFragment">;
          };
          readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemPrivateFragment">;
        };
      }>;
    };
  } | null;
};
export type ProductMemberMultiSelectQuery = {
  response: ProductMemberMultiSelectQuery$data;
  variables: ProductMemberMultiSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductMemberMultiSelectQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "productMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserDropdownItemPrivateFragment"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "UserDropdownItemFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductMemberMultiSelectQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "productMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isPrivateUser"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2188129e5ef5a7ec01d915e21432390d",
    "id": null,
    "metadata": {},
    "name": "ProductMemberMultiSelectQuery",
    "operationKind": "query",
    "text": "query ProductMemberMultiSelectQuery(\n  $id: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    ... on Product {\n      productMemberships {\n        edges {\n          node {\n            id\n            email\n            ...UserDropdownItemPrivateFragment\n            member {\n              id\n              fullName\n              ...UserDropdownItemFragment\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment UserDropdownItemFragment on User {\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment UserDropdownItemPrivateFragment on PrivateUser {\n  __isPrivateUser: __typename\n  email\n}\n"
  }
};
})();

(node as any).hash = "69a498cc265c373a6d6e1a2c0d58e012";

export default node;
