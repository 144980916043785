import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import { ProductType } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import { ExperienceSettingsFormStore } from "@/pricing/PricingFormFields"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import {
  useExperienceSettingsSelectedPlans_membershipPlansFragment$data,
  useExperienceSettingsSelectedPlans_membershipPlansFragment$key,
} from "@/product/settings/hooks/__generated__/useExperienceSettingsSelectedPlans_membershipPlansFragment.graphql"
import useExperienceSettingsSelectedPlans from "@/product/settings/hooks/useExperienceSettingsSelectedPlans"
import MembershipPlanExperiencePricingListItem from "@/product/settings/pricing/MembershipPlanExperiencePricingListItem"
import { NodeFromConnection } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoChip, DiscoDivider, DiscoSection, DiscoSwitch, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { Fragment, useEffect, useRef } from "react"

type Plan = NodeFromConnection<
  NonNullable<useExperienceSettingsSelectedPlans_membershipPlansFragment$data>["membershipPlans"]
>

interface Props extends TestIDProps {
  form: ExperienceSettingsFormStore | PathwaySettingsFormStore
  organizationKey: useExperienceSettingsSelectedPlans_membershipPlansFragment$key
  hideToggle?: boolean
  hideUnselectedPlans?: boolean
  productType?: ProductType
}

function ProductSettingsMembershipPlans({
  form,
  organizationKey,
  testid = "ProductSettingsMembershipPlans",
  hideToggle = false,
  hideUnselectedPlans = false,
  productType = "course",
}: Props) {
  const classes = useStyles()
  const experienceLabel = useLabel("admin_experience")
  const pathwayLabel = useLabel("pathway")
  const productLabel = productType === "pathway" ? pathwayLabel : experienceLabel
  const existingBenefits = form.state.benefits
  const existingBenefitsRef = useRef(existingBenefits)

  // When the form is submitted, reset the existing benefits
  useEffect(() => {
    existingBenefitsRef.current = form.initialState.benefits
  }, [form.initialState.benefits])

  const { hasCustomPlans, availableMembershipPlans, selectedPlans } =
    useExperienceSettingsSelectedPlans({
      organizationKey,
      benefits: form.state.benefits,
    })
  const plans = hideUnselectedPlans ? selectedPlans : availableMembershipPlans
  if (!hasCustomPlans) return null

  return (
    <DiscoSection className={classes.container} padding={0}>
      <div className={classes.content}>
        <ExperienceSettingsLabel
          title={"Available Membership Plans"}
          sectionId={"available_memberships"}
        />
        <DiscoText variant={"body-sm"} color={"text.secondary"} display={"inline"}>
          {`This ${productLabel.singular} is available through the following membership plans. `}
        </DiscoText>
      </div>

      {plans.map((plan) => (
        <Fragment key={plan.id}>
          <DiscoDivider marginTop={0} marginBottom={0} />
          <MembershipPlanExperiencePricingListItem
            testid={`${testid}.plan.${plan.name}`}
            form={form}
            membershipPlanKey={plan}
            cta={
              <div className={classes.cta}>
                <DiscoChip
                  label={pluralize("Product", plan.membershipBenefits?.totalCount, true)}
                />

                {!hideToggle && (
                  <DiscoSwitch
                    testid={`${testid}.plan.${plan.name}.switch`}
                    checked={form.state.benefits.some(
                      (b) => b.membershipPlanId === plan.id
                    )}
                    onChange={() => handleTogglePlan(plan)}
                  />
                )}
              </div>
            }
          />
        </Fragment>
      ))}
    </DiscoSection>
  )

  function handleTogglePlan(plan: Plan) {
    const existingBenefit = existingBenefitsRef.current.find(
      (b) => b.membershipPlanId === plan.id
    )
    const isToggleOn = form.state.benefits.some((b) => b.membershipPlanId === plan.id)

    // If toggling off, remove the benefit
    if (isToggleOn) {
      form.state.benefits = observable.array(
        form.state.benefits.filter((b) => b.membershipPlanId !== plan.id)
      )
    }

    // If toggling on, add the benefit
    else if (existingBenefit) {
      // If benefit already exists, add it back
      form.state.benefits.push(existingBenefit)
    }

    // Create benefit with null ID so the resolver knows to create one
    else {
      form.state.benefits.push({
        id: null,
        membershipPlanId: plan.id,
        pricing: {
          amountCents: 0,
          kind: "free",
          frequency: null,
        },
      })
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    margin: theme.spacing(1.5, 0, 2, 0),
    display: "flex",
    flexDirection: "column",
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5),
  },
  cta: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "space-between",
  },
}))

export default observer(ProductSettingsMembershipPlans)
