/**
 * @generated SignedSource<<02e590bba6b55e544a8862a698850b7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UnenrollProductMemberButtonFragment$data = {
  readonly id: string;
  readonly member: {
    readonly fullName: string;
  };
  readonly product: {
    readonly organization: {
      readonly id: string;
      readonly stripeDashboardURL: string | null;
    };
  };
  readonly productId: string;
  readonly role: ProductRole;
  readonly " $fragmentType": "UnenrollProductMemberButtonFragment";
};
export type UnenrollProductMemberButtonFragment$key = {
  readonly " $data"?: UnenrollProductMemberButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnenrollProductMemberButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnenrollProductMemberButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "member",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripeDashboardURL",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductMembership",
  "abstractKey": null
};
})();

(node as any).hash = "e063a89d1228c7b43ba52c6481eb81be";

export default node;
