import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LocationState } from "@/core/route/util/routeUtils"
import { GlobalID } from "@/relay/RelayTypes"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { isE2ETest } from "@utils/e2e"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 * Whether or not the user is impersonating a test user should be determined by checking this
 * variable on the global window instead of checking the query params
 */
declare global {
  interface Window {
    discoUlKey?: GlobalID
  }
}

export const IMPERSONATE_TEST_USER_PARAM = "i"

export function startImpersonatingTestUser(userLinkKey: GlobalID) {
  const url = new URL(window.location.href)
  url.pathname = ROUTE_NAMES.COMMUNITY.HOME.ROOT
  url.searchParams.set(IMPERSONATE_TEST_USER_PARAM, userLinkKey)

  if (isE2ETest()) {
    window.location.href = url.toString()
  } else {
    window.open(url.toString(), "_blank")
  }
}

export function stopImpersonatingTestUser() {
  // Reload the current page without viewAs params set.
  const url = new URL(window.location.href)
  url.searchParams.delete(IMPERSONATE_TEST_USER_PARAM)
  window.location.href = url.toString()
}

const AUTH_ROUTES = new Set<string>(Object.values(ROUTE_NAMES.AUTHENTICATION))

/**
 * Initialize hook at root app level to append IMPERSONATE_TEST_USER_PARAM to query params if it was removed
 * while still impersonating a test user
 */
function useInitImpersonateTestUser() {
  const { pathname } = useLocation<LocationState>()
  const [params, setParams] = useQueryParamState<{ i?: GlobalID }>()

  // Set the global var immediately for use in GraphQL queries if necessary
  const isExcludedPath = AUTH_ROUTES.has(pathname)
  if (
    !isExcludedPath &&
    params[IMPERSONATE_TEST_USER_PARAM] &&
    params[IMPERSONATE_TEST_USER_PARAM] !== window.discoUlKey
  ) {
    window.discoUlKey = params[IMPERSONATE_TEST_USER_PARAM]
  }

  useEffect(() => {
    // If navigating to excluded path, do not append i param, and close/reload the
    // window if we are currently impersonating a test user
    if (isExcludedPath) {
      if (window.discoUlKey) stopImpersonatingTestUser()
      return
    }

    // If IMPERSONATE_USER_PARAM was on, persist param on navigation
    if (!params[IMPERSONATE_TEST_USER_PARAM] && window.discoUlKey) {
      setParams({ [IMPERSONATE_TEST_USER_PARAM]: window.discoUlKey }, "replace")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExcludedPath, stopImpersonatingTestUser, params[IMPERSONATE_TEST_USER_PARAM]])
}
export default useInitImpersonateTestUser
