import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import ProductRegistrationCheckboxes from "@/product/register/steps/ProductRegistrationCheckboxes"
import ProductRegistrationProfileStep from "@/product/register/steps/ProductRegistrationProfileStep"
import Relay from "@/relay/relayUtils"
import RedirectToSignInLink from "@/user/common/prompt-to-sign-in-link/PromptToSignInLink"
import SocialLogin from "@components/social-login/SocialLogin"
import SSOLogin from "@components/sso-login/SSOLogin"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoDivider,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"

interface Props extends TestIDProps {
  store: CheckoutStore
}

function ProductRegistrationStartStep({ store }: Props) {
  const { authUser } = useAuthUser()
  const activeOrganization = useActiveOrganization()

  const [didntAcceptToc, setDidntAcceptToc] = useState(false)

  // If this is an existing user, push them to the profile page
  if (authUser || store.hasSelectedEmailSignup) {
    return <ProductRegistrationProfileStep store={store} />
  }

  return (
    <>
      {/* Only display the "how would you like to register */}
      {store.checkout?.invitation ? (
        <DiscoDivider marginTop={2} marginBottom={2} />
      ) : (
        <>
          <DiscoText variant={"body-md-600"}>{"Registration"}</DiscoText>
          <DiscoText variant={"body-md"} color={"text.secondary"}>
            {"How would you like to register?"}
          </DiscoText>

          <DiscoDivider marginTop={3} marginBottom={3} />
        </>
      )}

      {/* Show the ToC */}
      <ProductRegistrationCheckboxes
        testid={
          store.product?.type === "membership_plan"
            ? "MembershipPlanRegistrationCheckboxes"
            : "ProductRegistrationCheckboxes"
        }
        store={store}
        showErrorMessage={didntAcceptToc}
        setShowErrorMessage={setDidntAcceptToc}
      />

      {/* Ask the user how they want to continue */}
      {activeOrganization?.authProvider?.status === "enabled" ? (
        <SSOLogin
          labelPrefix={"Continue"}
          width={"100%"}
          loginSource={"registration"}
          redirectDestination={window.location.href}
          onClick={() => {
            if (store.hasAcceptedTerms) {
              return true
            }
            setDidntAcceptToc(true)
            return false
          }}
        />
      ) : (
        <>
          <SocialLogin
            onClick={() => {
              if (store.hasAcceptedTerms) {
                return true
              }
              setDidntAcceptToc(true)
              return false
            }}
            width={"100%"}
            loginSource={"registration"}
            redirectDestination={window.location.href}
          />

          <DiscoButton
            onClick={() => {
              // Check whether we have accepted the TOC
              if (store.hasAcceptedTerms) {
                store.hasSelectedEmailSignup = true
              } else {
                setDidntAcceptToc(true)
              }
            }}
            testid={"ProductRegistrationStartStep.continue-with-email-button"}
            width={"100%"}
          >
            {"Continue with Email"}
          </DiscoButton>

          {/* Show the sign in blurb */}
          <RedirectToSignInLink testid={"ProductRegistrationStartStep.login-link"} />
        </>
      )}
    </>
  )
}

export const ProductRegistrationStartStepSkeleton: React.FC = () => {
  return (
    <>
      <DiscoTextSkeleton variant={"body-md-600"} width={"45%"} />
      <DiscoTextSkeleton variant={"body-sm"} marginBottom={2} width={"80%"} />
      <Skeleton width={"100%"} height={75} />
      <DiscoButtonSkeleton width={"100%"} />
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ProductRegistrationStartStep),
  skeleton: ProductRegistrationStartStepSkeleton,
})
