/**
 * @generated SignedSource<<6f811115204e4d01e6c19264dd131191>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UpdateProductRoleButtonFragment$data = {
  readonly id: string;
  readonly member: {
    readonly fullName: string;
  };
  readonly role: ProductRole;
  readonly " $fragmentType": "UpdateProductRoleButtonFragment";
};
export type UpdateProductRoleButtonFragment$key = {
  readonly " $data"?: UpdateProductRoleButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateProductRoleButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateProductRoleButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "member",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductMembership",
  "abstractKey": null
};

(node as any).hash = "fc74623e698d5b105cf433a01a197bd8";

export default node;
