/**
 * @generated SignedSource<<140e9db1bf2f3d73798d5039613b76f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExperienceSettingsTeamTabFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "ExperienceSettingsTeamTabFragment";
};
export type ExperienceSettingsTeamTabFragment$key = {
  readonly " $data"?: ExperienceSettingsTeamTabFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceSettingsTeamTabFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperienceSettingsTeamTabFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "b8bb2ae32f1c15e620d590a21dc932a4";

export default node;
