/**
 * @generated SignedSource<<3c9e40c2dda6ba725370646ea90cf9b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationRole = "admin" | "member" | "owner" | "%future added value";
export type DeleteOrganizationMembershipInput = {
  organizationMembershipId: string;
};
export type RemoveCommunityMemberButtonMutation$variables = {
  input: DeleteOrganizationMembershipInput;
};
export type RemoveCommunityMemberButtonMutation$data = {
  readonly response: {
    readonly deletedTestMembershipIds: ReadonlyArray<string> | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly organization: {
        readonly adminsCount: number;
        readonly membersCount: number;
        readonly viewerMembership: {
          readonly id: string;
          readonly role: OrganizationRole;
        } | null;
      };
    } | null;
  };
};
export type RemoveCommunityMemberButtonMutation = {
  response: RemoveCommunityMemberButtonMutation$data;
  variables: RemoveCommunityMemberButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membersCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminsCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationMembership",
  "kind": "LinkedField",
  "name": "viewerMembership",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedTestMembershipIds",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveCommunityMemberButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteOrganizationMemberResponse",
        "kind": "LinkedField",
        "name": "deleteOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveCommunityMemberButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteOrganizationMemberResponse",
        "kind": "LinkedField",
        "name": "deleteOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e5cbfee8a0682578710c324e84af079",
    "id": null,
    "metadata": {},
    "name": "RemoveCommunityMemberButtonMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveCommunityMemberButtonMutation(\n  $input: DeleteOrganizationMembershipInput!\n) {\n  response: deleteOrganizationMembership(input: $input) {\n    node {\n      id\n      organization {\n        membersCount\n        adminsCount\n        viewerMembership {\n          id\n          role\n        }\n        id\n      }\n    }\n    deletedTestMembershipIds\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3945cf44c1f78a2ac6b7e16938af2cd3";

export default node;
