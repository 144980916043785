/**
 * @generated SignedSource<<b2cc9951feb35b5346167755365cb062>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PricingFrequency = "annually" | "monthly" | "weekly" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MembershipPlanRegistrationFragment$data = {
  readonly id: string;
  readonly myCheckout: {
    readonly id: string;
  } | null;
  readonly registrationPricing: {
    readonly basePrice: number;
    readonly currency: string;
    readonly pricing: {
      readonly frequency: PricingFrequency | null;
      readonly " $fragmentSpreads": FragmentRefs<"pricingUtils_usePricingDisplayValue">;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProductOnboardingInfoSection_Product" | "ProductRegistrationSectionFragment">;
  readonly " $fragmentType": "MembershipPlanRegistrationFragment";
};
export type MembershipPlanRegistrationFragment$key = {
  readonly " $data"?: MembershipPlanRegistrationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MembershipPlanRegistrationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipPlanRegistrationFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Checkout",
      "kind": "LinkedField",
      "name": "myCheckout",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RegistrationPricing",
      "kind": "LinkedField",
      "name": "registrationPricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pricing",
          "kind": "LinkedField",
          "name": "pricing",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "frequency",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "pricingUtils_usePricingDisplayValue"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductRegistrationSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductOnboardingInfoSection_Product"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "e343f1ba4ed985d60ba5c7e7df5f5d4a";

export default node;
